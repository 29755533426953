import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { cookiesConstants, getCookie } from '@magalu/mixer-utils';
import serviceContentFallback from './serviceContentFallback';
var useAdaptData = function useAdaptData(_ref) {
  var _serviceContentData$f, _data$product, _data$product2, _data$product2$instal, _structure$config, _structure$config2, _data$product3, _data$product3$instal, _response$cart;
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var router = useRouter();
  var serviceContentData = (data == null ? void 0 : data.serviceContent) || serviceContentFallback;
  var servicesById = serviceContentData == null ? void 0 : (_serviceContentData$f = serviceContentData.financialServicesContent) == null ? void 0 : _serviceContentData$f.reduce(function (map, service) {
    return _extends({}, map, _defineProperty({}, service.code, service));
  }, {});
  var financialServices = (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.financialServices) || [];
  var adaptedData = financialServices == null ? void 0 : financialServices.map(function (_ref2) {
    var _servicesById$id2, _servicesById$id3, _servicesById$id4;
    var id = _ref2.id,
      description = _ref2.description,
      offers = _ref2.offers;
    var _servicesById$id = servicesById[id],
      chargingTerms = _servicesById$id.chargingTerms,
      code = _servicesById$id.code,
      videoUrl = _servicesById$id.luContentURL,
      descriptionTac = _servicesById$id.description;
    return {
      chargingTerms: chargingTerms,
      code: code,
      description: description,
      descriptionTac: descriptionTac,
      extraInfo: _extends({
        agreementText: (_servicesById$id2 = servicesById[id]) == null ? void 0 : _servicesById$id2.agreementText,
        benefit: (_servicesById$id3 = servicesById[id]) == null ? void 0 : _servicesById$id3.benefit,
        videoUrl: videoUrl
      }, staticProps.extraInfo || {}),
      id: id,
      offers: offers,
      title: (_servicesById$id4 = servicesById[id]) == null ? void 0 : _servicesById$id4.title
    };
  });
  var product = (data == null ? void 0 : data.product) ? function (_ref3) {
    var image = _ref3.image,
      price = _ref3.price,
      seller = _ref3.seller,
      title = _ref3.title,
      variationId = _ref3.variationId;
    return {
      id: (structure == null ? void 0 : structure.route.productId) || variationId,
      image: image,
      price: {
        bestPrice: Number(price == null ? void 0 : price.bestPrice),
        fullPrice: Number(price == null ? void 0 : price.fullPrice),
        paymentMethodDescription: price == null ? void 0 : price.paymentMethodDescription
      },
      seller: {
        id: seller == null ? void 0 : seller.id
      },
      title: title
    };
  }(data == null ? void 0 : data.product) : undefined;
  var installmentConditions = data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$instal = _data$product2.installment) == null ? void 0 : _data$product2$instal.paymentMethodDescription;
  var authorizationTermText = serviceContentData.authorizationTermText,
    footerContent = serviceContentData.footerContent;
  var ML2_SID = cookiesConstants.ML2_SID;
  var cart = {
    extensions: staticProps == null ? void 0 : staticProps.extensions,
    id: getCookie(ML2_SID),
    url: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cartUrl
  };
  var partner = {
    id: structure == null ? void 0 : structure.cookies.partnerId
  };
  var channel = structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.salesChannelId;
  var location = structure == null ? void 0 : structure.location;
  var rawProduct = data == null ? void 0 : data.product;
  var response = {
    authorizationTermText: authorizationTermText,
    cart: cart,
    channel: channel,
    footerContent: footerContent,
    installmentConditions: installmentConditions,
    installments: data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$instal = _data$product3.installment) == null ? void 0 : _data$product3$instal.quantity,
    location: location,
    partner: partner,
    product: product,
    rawProduct: rawProduct,
    services: adaptedData
  };
  var cartId = response == null ? void 0 : (_response$cart = response.cart) == null ? void 0 : _response$cart.id;
  useEffect(function () {
    if (!cartId) {
      var _data$product4;
      router.replace("/".concat(data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : _data$product4.url));
    }
  }, [cartId]);
  return response;
};
export default useAdaptData;
import React from 'react';
var Ebit = function Ebit() {
  return React.createElement("script", {
    defer: true,
    id: "getSelo",
    fetchpriority: "low",
    src: "https://imgs.ebit.com.br/ebitBR/selo-ebit/js/getSelo.js?552"
  });
};
Ebit.ssr = true;
export default Ebit;
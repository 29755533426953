import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22;
import styled, { css } from 'styled-components';
import Avatar from '@magalu/stereo-ui/molecules/Avatar';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon } from '@magalu/stereo-ui-icons';
import { getTextColor, getBackgroundColor, themeGet } from '@magalu/stereo-ui-styles';
export var BlueStrip = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  height: 60px;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 8;\n"])), function (props) {
  return getBackgroundColor('primary')(props);
});
export var CapitalizedText = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-transform: capitalize;\n"])));
export var DefaultIcon = styled(Icon)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  fill: ", ";\n"])), function (_ref) {
  var color = _ref.color;
  return color;
});
export var PromoterAvatar = styled(Avatar)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: ", ";\n  width: ", ";\n  margin-right: ", ";\n  flex: 0 0 auto;\n\n  & svg {\n    height: ", ";\n    width: ", ";\n    fill: ", ";\n    color: ", ";\n  }\n\n  ", "\n"])), function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, function (props) {
  return props.margin || '16px';
}, function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, themeGet('palette.primary.base'), themeGet('palette.primary.base'), function (_ref2) {
  var isMobile = _ref2.isMobile;
  return isMobile && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      border: 4px solid ", ";\n      display: flex;\n      z-index: 9;\n      align-items: center;\n      justify-content: center;\n    "])), getBackgroundColor('white'));
});
export var PromoterAvatarContainer = styled(Box)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  flex: 0 0 auto;\n  min-width: 116px;\n"])));
export var PromoterContainer = styled(Flex)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  max-width: 100%;\n  overflow: hidden;\n  width: 610px;\n  justify-self: center;\n\n  ", "\n"])), function (_ref3) {
  var isMobile = _ref3.isMobile;
  return isMobile && css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      display: flex;\n      flex-direction: column;\n      width: 100%;\n    "])));
});
export var PromoterData = styled(Flex)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  width: calc(100% - 176px);\n\n  ", "\n"])), function (_ref4) {
  var isMobile = _ref4.isMobile;
  return isMobile && css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n      width: 100%;\n      padding: 8px;\n    "])));
});
export var PromoterDataContainer = styled(Flex)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  flex: 1;\n  flex-direction: column;\n"])));
export var PromoterDescriptionContainer = styled(Text)(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  line-height: 1.4em;\n  max-width: 400px;\n  width: 100%;\n\n  ", "\n"])), function (_ref5) {
  var isOpen = _ref5.isOpen;
  return !isOpen && css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "])));
});
export var PromoterHeaderContainer = styled.section(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  align-items: center;\n  box-shadow: 0px 2px 5px 0px rgba(29, 43, 122, 0.15);\n  display: flex;\n  justify-content: center;\n  margin-bottom: 4px;\n  width: 100%;\n  background: ", ";\n"])), getBackgroundColor('white'));
export var PromoterHeaderMobileWrapper = styled(Box)(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  position: relative;\n  background: ", ";\n"])), getBackgroundColor('white'));
export var PromoterWrapper = styled(Box)(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  max-width: 1366px;\n  padding: 24px;\n"])));
export var SeeMoreButton = styled(Text)(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  appearance: none;\n  background: none;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  width: 10ch;\n  margin-top: 8px;\n\n  svg {\n    fill: ", ";\n    transition: transform 0.5s ease;\n  }\n\n  ", "\n"])), function (props) {
  return getTextColor('primary')(props);
}, function (props) {
  return getTextColor('primary')(props);
}, function (_ref6) {
  var isOpen = _ref6.isOpen;
  return isOpen && css(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n      svg {\n        transform: rotate(180deg);\n      }\n    "])));
});
export var ShareIcon = styled(DefaultIcon)(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral([""])));
export var SocialIcon = styled(DefaultIcon)(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral([""])));
export var SocialShareContainer = styled(Flex)(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref7) {
  var isMobile = _ref7.isMobile;
  return isMobile && css(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n      position: relative;\n      top: -10px;\n      flex-wrap: wrap-reverse;\n      justify-content: flex-end;\n    "])));
});
import React, { memo } from 'react';
import { func, string, shape, bool } from 'prop-types';
import ServicesAuthorizationTerm from '../ServicesAuthorizationTerm';
import { ActionButtonsWrapper, Button, ButtonsWrapper, Wrapper } from './ServicesActionButtons.styles';
var ServicesActionButtons = function ServicesActionButtons(_ref) {
  var authorizationTermText = _ref.authorizationTermText,
    handleAcquireServices = _ref.handleAcquireServices,
    hasCancelButton = _ref.hasCancelButton,
    titleCancelButton = _ref.titleCancelButton;
  return React.createElement(ActionButtonsWrapper, null, React.createElement(Wrapper, {
    "data-testid": "buttons-wrapper"
  }, React.createElement(ServicesAuthorizationTerm, {
    authorizationTermText: authorizationTermText
  }), hasCancelButton && React.createElement(ButtonsWrapper, null, React.createElement(Button, {
    color: "secondary",
    variation: "outline",
    uppercase: false,
    onClick: handleAcquireServices.continueWithoutServices,
    "data-testid": "no-services"
  }, titleCancelButton)), React.createElement(ButtonsWrapper, null, React.createElement(Button, {
    disabled: handleAcquireServices.disableContinueAddingServices,
    "aria-disabled": handleAcquireServices.disableContinueAddingServices,
    color: "secondary",
    uppercase: false,
    onClick: handleAcquireServices.continueAddingServices,
    "data-testid": "acquire-services"
  }, "Incluir Prote\xE7\xE3o"))));
};
ServicesActionButtons.defaultProps = {
  authorizationTermText: 'Termos de autorização',
  hasCancelButton: false,
  titleCancelButton: 'Agora não'
};
process.env.NODE_ENV !== "production" ? ServicesActionButtons.propTypes = {
  authorizationTermText: string,
  handleAcquireServices: shape({
    continueAddingServices: func.isRequired,
    continueWithoutServices: func.isRequired
  }).isRequired,
  hasCancelButton: bool,
  titleCancelButton: string
} : void 0;
ServicesActionButtons.ssr = true;
export default memo(ServicesActionButtons);
import { bool, arrayOf, number, shape, string } from 'prop-types';
export var ServicesWrapperPropTypes = {
  services: arrayOf(shape({
    benefit: arrayOf(shape({
      description: string
    })),
    extraInfo: shape({
      agreementText: string,
      benefit: arrayOf(shape({
        description: string
      })),
      label: string,
      labelBold: string,
      title: string,
      videoUrl: string
    }),
    label: string,
    labelBold: string,
    period: shape({
      options: arrayOf(shape({
        checked: bool,
        recommended: bool,
        selectedOfferPrice: shape({
          subtitle: string,
          title: shape({
            bold: string,
            text: string
          })
        }),
        title: string,
        value: number
      })),
      value: number
    }),
    title: string,
    videoUrl: string
  })).isRequired
};
import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, bool, number, objectOf, shape, string } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ServicesActionButtons from '../ServicesActionButtons';
import ServicesWrapper from '../ServicesWrapper';
import LegalInfo from './LegalInfo';
import useFinancialServicesNew from './useFinancialServicesNew';
import { ServicesContainer, ServiceCardsWrapper, Title } from './FinancialServicesNewLayout.styles';
var FinancialServicesNewLayout = function FinancialServicesNewLayout(props) {
  var staticProps = props["static"];
  var pageTitle = staticProps.pageTitle,
    extraInfo = staticProps.extraInfo;
  var showInfoComponent = extraInfo.showInfoComponent;
  var _useFinancialServices = useFinancialServicesNew(props),
    actionButtonProps = _useFinancialServices.actionButtonProps,
    legalInfos = _useFinancialServices.legalInfos,
    services = _useFinancialServices.services;
  return React.createElement(ServicesContainer, {
    "data-testid": "services-container"
  }, pageTitle && React.createElement(Title, {
    "data-testid": "services-title"
  }, staticProps.pageTitle), React.createElement(ServicesWrapper, {
    "data-testid": "services-wrapper",
    services: services
  }), showInfoComponent && React.createElement(ServiceCardsWrapper, null, legalInfos.map(function (legalInfo) {
    return React.createElement(LegalInfo, _extends({
      key: legalInfo == null ? void 0 : legalInfo.key
    }, legalInfo));
  })), React.createElement(ServicesActionButtons, _extends({}, actionButtonProps, {
    hasCancelButton: true
  })));
};
FinancialServicesNewLayout.defaultProps = {
  data: {
    product: {
      financialServices: [],
      installment: {}
    },
    serviceContent: {
      financialServicesContent: [],
      footerContent: []
    }
  },
  "static": {
    extraInfo: {},
    overrideDeclineMessages: {}
  }
};
process.env.NODE_ENV !== "production" ? FinancialServicesNewLayout.propTypes = {
  data: shape({
    product: shape({
      financialServices: arrayOf(shape({
        description: string,
        id: string
      })),
      installment: shape({
        quantity: number
      })
    }),
    serviceContent: shape({
      financialServicesContent: arrayOf(shape({
        title: string
      })),
      footerContent: arrayOf(shape({
        title: string
      }))
    })
  }),
  "static": shape({
    extraInfo: shape({
      label: string,
      labelBold: string,
      showInfoComponent: bool,
      title: string
    }),
    overrideDeclineMessages: objectOf(shape({
      description: string
    }))
  })
} : void 0;
FinancialServicesNewLayout.ssr = true;
FinancialServicesNewLayout.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  serviceContent: {
    query: 'ServiceContentQuery'
  }
};
export default withLayoutProps(FinancialServicesNewLayout);
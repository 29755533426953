import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import ServiceCheckbox from './ServiceCheckbox';
import { HorizontalRule, RecommendedContainer, RecommendedText, Wrapper } from './ServicesCard.styles';
var ServicesCard = function ServicesCard(props) {
  var title = props.title,
    checked = props.checked,
    onChange = props.onChange,
    value = props.value,
    recommended = props.recommended,
    selectedOfferPrice = props.selectedOfferPrice;
  return React.createElement(Wrapper, {
    "data-testid": "service-card"
  }, React.createElement(RecommendedContainer, {
    recommended: recommended,
    "data-testid": "recommended-container"
  }, React.createElement(RecommendedText, null, "Recomendado")), React.createElement(ServiceCheckbox, {
    checked: checked,
    onChange: onChange,
    title: title,
    offerId: value,
    selectedOfferPrice: selectedOfferPrice
  }), React.createElement(HorizontalRule, {
    size: 2
  }));
};
ServicesCard.defaultProps = {
  selectedOfferPrice: {}
};
process.env.NODE_ENV !== "production" ? ServicesCard.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  recommended: bool.isRequired,
  selectedOfferPrice: shape({
    subtitle: string,
    title: shape({
      bold: string,
      text: string
    })
  }),
  title: string.isRequired,
  value: number.isRequired
} : void 0;
export default ServicesCard;
import _extends from "@babel/runtime/helpers/esm/extends";
import crypto from 'crypto';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { slug as slugify } from '@magalu/mixer-utils';
var hashString = function hashString(str) {
  return crypto.createHash('sha256').update(str).digest('hex');
};
var cachePolicies = {
  Brand: {
    keyFields: ['slug']
  },
  Category: {
    fields: {
      meta: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      },
      subcategories: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      }
    },
    keyFields: function keyFields(object) {
      return (object == null ? void 0 : object.subcategories) ? "".concat(object.id, ":Sub") : "".concat(object.id, ":NoSub");
    }
  },
  FilterValueResponse: {
    keyFields: function keyFields(object) {
      return object.type ? ['slug', 'id', 'type'] : ['slug', 'id'];
    }
  },
  ListConversations: {
    fields: {
      listConversations: offsetLimitPagination()
    }
  },
  Product: {
    fields: {
      seller: {
        details: {
          address: {
            merge: function merge(existing, incoming) {
              return _extends({}, existing, incoming);
            }
          }
        }
      }
    },
    keyFields: function keyFields(object) {
      var _object$ads$id, _object$ads;
      return "".concat(object.variationId, ":").concat(object.id, ":").concat((_object$ads$id = object == null ? void 0 : (_object$ads = object.ads) == null ? void 0 : _object$ads.id) !== null && _object$ads$id !== void 0 ? _object$ads$id : '');
    }
  },
  Query: {
    fields: {
      recommendation: {
        merge: function merge(existing, incoming) {
          return _extends({}, existing, incoming);
        }
      }
    }
  },
  Recommendation: {
    keyFields: function keyFields(_ref) {
      var id = _ref.id,
        title = _ref.title,
        designTokenId = _ref.designTokenId,
        products = _ref.products;
      var productIds = (products == null ? void 0 : products.map(function (p) {
        return p.id;
      }).join('_')) || 'no-products';
      var designToken = (designTokenId == null ? void 0 : designTokenId.split('.')[1]) || 'no-design-token-id';
      var baseString = "".concat(slugify(title), ":").concat(designToken, ":").concat(productIds);
      return "".concat(id, ":").concat(hashString(baseString));
    }
  },
  Variation: {
    keyFields: function keyFields(object) {
      return "".concat(object.id, ":").concat(object.type);
    }
  }
};
export default cachePolicies;
import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import ServicesOfferPriceAndInstallments from '../ServicesOfferPriceAndInstallments';
import { Checkbox } from './ServicesCard.styles';
var ServiceCheckbox = function ServiceCheckbox(_ref) {
  var checked = _ref.checked,
    onChange = _ref.onChange,
    offerId = _ref.offerId,
    title = _ref.title,
    selectedOfferPrice = _ref.selectedOfferPrice;
  var handleChange = function handleChange(event) {
    onChange(event.target.checked ? offerId : null);
  };
  return React.createElement(Box, {
    pl: 3,
    pr: 3
  }, React.createElement(Checkbox, {
    checked: checked,
    onChange: handleChange
  }, React.createElement(Text, {
    fontWeight: "bold",
    fontSize: 2
  }, title)), React.createElement(Flex, {
    justifyContent: "space-between",
    alignItems: "left",
    marginTop: "20px"
  }, React.createElement(ServicesOfferPriceAndInstallments, selectedOfferPrice)));
};
process.env.NODE_ENV !== "production" ? ServiceCheckbox.propTypes = {
  checked: bool.isRequired,
  offerId: number.isRequired,
  onChange: func.isRequired,
  selectedOfferPrice: shape({
    subtitle: string,
    title: shape({
      bold: string,
      text: string
    })
  }).isRequired,
  title: string.isRequired
} : void 0;
export default ServiceCheckbox;
import _extends from "@babel/runtime/helpers/esm/extends";
import getOffersInput from './getOffersInput';
var getServiceInfos = function getServiceInfos(props) {
  var service = props.service,
    servicesSelectedOfferId = props.servicesSelectedOfferId,
    installments = props.installments,
    onSelectedServicesIdsChanged = props.onSelectedServicesIdsChanged,
    onServicesSelectedOfferChange = props.onServicesSelectedOfferChange,
    publishKnowMore = props.publishKnowMore,
    publishServiceClickEvent = props.publishServiceClickEvent,
    publishServiceMoreDetails = props.publishServiceMoreDetails,
    publishServiceVideo = props.publishServiceVideo;
  return {
    extraInfo: _extends({}, service.extraInfo, {
      onInfoClick: function onInfoClick() {
        publishKnowMore({
          service: service.description
        });
      },
      onModalOpen: function onModalOpen(_ref) {
        var open = _ref.open,
          modalType = _ref.modalType;
        if (open && modalType === 'agreement') {
          publishServiceMoreDetails({
            service: service.description
          });
        }
        if (open && modalType === 'video') {
          publishServiceVideo({
            service: service.description
          });
        }
      }
    }),
    key: service.id,
    period: getOffersInput({
      installments: installments,
      onSelectedServicesIdsChanged: onSelectedServicesIdsChanged,
      onServicesSelectedOfferChange: onServicesSelectedOfferChange,
      publishServiceClickEvent: publishServiceClickEvent,
      service: service,
      serviceIdToOfferId: servicesSelectedOfferId
    })
  };
};
export default getServiceInfos;
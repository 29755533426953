import React from 'react';
import Text from '@magalu/stereo-ui/atoms/Text';
import { string } from 'prop-types';
import { Image, Wrapper } from './ServicesProductCard.styles';
var ServicesProductCard = function ServicesProductCard(_ref) {
  var src = _ref.src,
    alt = _ref.alt,
    title = _ref.title;
  return React.createElement(Wrapper, {
    "data-testid": "product-service"
  }, React.createElement(Image, {
    src: src,
    alt: alt
  }), React.createElement(Text, null, title));
};
process.env.NODE_ENV !== "production" ? ServicesProductCard.propTypes = {
  alt: string.isRequired,
  src: string.isRequired,
  title: string.isRequired
} : void 0;
export default ServicesProductCard;
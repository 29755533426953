import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { string } from 'prop-types';
import { ClickableText, DialogContainer, DialogContent, MainText, Wrapper } from './ServicesAuthorizationTerm.styles';
var ServicesAuthorizationTerm = function ServicesAuthorizationTerm(_ref) {
  var authorizationTermText = _ref.authorizationTermText;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showingTac = _useState2[0],
    setShowingTac = _useState2[1];
  return React.createElement(React.Fragment, null, React.createElement(Wrapper, null, React.createElement(MainText, {
    textAlign: "center"
  }, "Ao clicar em", React.createElement("strong", null, ' "Incluir proteção" '), "voc\xEA concorda com os \xA0", React.createElement(ClickableText, {
    as: "span",
    "data-testid": "auth-term-label",
    role: "button",
    tabIndex: "0",
    onClick: function onClick() {
      return setShowingTac(true);
    }
  }, React.createElement("strong", null, "Termos de autoriza\xE7\xE3o de cobran\xE7a")))), React.createElement(DialogContainer, {
    "data-testid": "auth-term-dialog",
    onClickOutside: function onClickOutside() {
      return setShowingTac(false);
    },
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: showingTac,
    showClose: true
  }, React.createElement(DialogContent, {
    dangerouslySetInnerHTML: {
      __html: authorizationTermText
    }
  })));
};
ServicesAuthorizationTerm.defaultProps = {
  authorizationTermText: 'Termos de autorização'
};
process.env.NODE_ENV !== "production" ? ServicesAuthorizationTerm.propTypes = {
  authorizationTermText: string
} : void 0;
export default ServicesAuthorizationTerm;
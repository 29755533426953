import { slug } from '@magalu/mixer-utils';
export var certificatesEventsFooter = function certificatesEventsFooter(data) {
  return {
    content_id: "selo:".concat((data == null ? void 0 : data.title) ? slug(data == null ? void 0 : data.title) : null),
    content_type: 'footer',
    event: 'select_content'
  };
};
export var eventsFooter = function eventsFooter(data, title) {
  var titleDepartament = title ? slug(title) : null;
  var label = (data == null ? void 0 : data.content[0].label) ? slug(data == null ? void 0 : data.content[0].label) : null;
  return {
    content_id: "".concat(titleDepartament, ":").concat(label),
    content_type: 'footer',
    event: 'select_content'
  };
};
export var paymentEventsFooter = function paymentEventsFooter(data) {
  return {
    content_id: "forma-de-pagamento:".concat((data == null ? void 0 : data.title) ? slug(data == null ? void 0 : data.title) : null),
    content_type: 'footer',
    event: 'select_content'
  };
};
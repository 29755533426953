import React from 'react';
import { bool } from 'prop-types';
import { Flex, Grid, Text } from '@magalu/stereo-ui';
import { Icon } from '@magalu/stereo-ui-icons';
import { RecommendedProductTitle } from './RecommendedProductTitle';
import { IconTextContainer, ItemTitle } from './RecommendedProduct.styles';
var RecommendedProduct = function RecommendedProduct(_ref) {
  var showDialog = _ref.showDialog;
  return React.createElement(Flex, {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 4
  }, !showDialog && React.createElement(RecommendedProductTitle, {
    color: "primary.base",
    fontWeight: "bold",
    fontSize: 1
  }), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Entrega r\xE1pida e barata"), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Receba antes e pague menos pelo envio do seu pedido."))), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Mais seguran\xE7a"), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Sua compra protegida do in\xEDcio ao fim da entrega, at\xE9 chegar em suas m\xE3os."))), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Mais satisfa\xE7\xE3o"), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Clientes Magalu aprovam e recomendam a experi\xEAncia Full."))));
};
process.env.NODE_ENV !== "production" ? RecommendedProduct.propTypes = {
  showDialog: bool
} : void 0;
RecommendedProduct.defaultProps = {
  showDialog: false
};
RecommendedProduct.Title = RecommendedProductTitle;
export default RecommendedProduct;
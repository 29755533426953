import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import HorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import { arrayOf, bool, shape, string } from 'prop-types';
import { parseVideoUrl } from '@magalu/mixer-utils';
import Benefits from './Benefits';
import Dialog from './Dialog';
import { ArrowIcon, AgreementLabel, BenefitContainer, RuleWithMargin, VideoContent } from './ServicesInfo.styles';
import useServicesInfo from './useServicesInfo';
var ServicesInfo = function ServicesInfo(props) {
  var showVideo = props.showVideo,
    videoUrl = props.videoUrl;
  var _useServicesInfo = useServicesInfo(props),
    agreement = _useServicesInfo.agreement,
    benefit = _useServicesInfo.benefit,
    dialog = _useServicesInfo.dialog,
    handleClickEvent = _useServicesInfo.handleClickEvent,
    video = _useServicesInfo.video;
  return React.createElement(React.Fragment, null, React.createElement(BenefitContainer, {
    mb: 2,
    mt: 3
  }, React.createElement(Benefits, {
    benefit: benefit
  })), video.label && React.createElement(React.Fragment, null, React.createElement(RuleWithMargin, null), showVideo && React.createElement(VideoContent, null, React.createElement("iframe", {
    src: parseVideoUrl(videoUrl),
    title: "YouTube video player",
    frameBorder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
    "data-testid": "video-content"
  }))), agreement.title && React.createElement(React.Fragment, null, React.createElement(RuleWithMargin, null), React.createElement(Flex, {
    alignItems: "center",
    pr: 3
  }, React.createElement(AgreementLabel, {
    onClick: function onClick() {
      return handleClickEvent('agreement');
    },
    "data-testid": "agreement-trigger",
    mb: 3,
    mt: 3
  }, agreement.title), React.createElement(ArrowIcon, {
    name: "ChevronRight"
  }))), React.createElement(HorizontalRule, null), React.createElement(Dialog, _extends({
    handleClickEvent: handleClickEvent
  }, dialog)));
};
ServicesInfo.defaultProps = {
  agreementText: '',
  benefit: [{
    description: ''
  }],
  label: '',
  labelBold: '',
  showVideo: false,
  title: '',
  videoUrl: ''
};
process.env.NODE_ENV !== "production" ? ServicesInfo.propTypes = {
  agreementText: string,
  benefit: arrayOf(shape({
    description: string
  })),
  label: string,
  labelBold: string,
  showVideo: bool,
  title: string,
  videoUrl: string
} : void 0;
ServicesInfo.ssr = true;
export default ServicesInfo;
import _extends from "@babel/runtime/helpers/esm/extends";
import { getCookie, cookiesConstants } from '@magalu/mixer-utils';
import { ADD_TO_BAG } from '../../../commons/constants';
export var getCustomerId = function getCustomerId(structure) {
  var _structure$cookies, _structure$cookies$ac, _structure$cookies2;
  var CUSTOMER_ID = cookiesConstants.CUSTOMER_ID;
  var structureCustomerId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id) || (structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.customerId);
  return structureCustomerId || getCookie(CUSTOMER_ID) || 'unknown';
};
export var hasMinimalRecommendations = function hasMinimalRecommendations(showcases, minShowcases) {
  return !!(showcases == null ? void 0 : showcases.length) && (showcases == null ? void 0 : showcases.length) >= minShowcases;
};
export var parseQuickClickData = function parseQuickClickData(quickClickData) {
  var _structure$cookies3, _structure$cookies3$a;
  var _ref = quickClickData || {},
    cartExtensions = _ref.cartExtensions,
    publish = _ref.publish,
    showcaseArr = _ref.showcaseArr,
    structure = _ref.structure;
  var customerId = structure == null ? void 0 : (_structure$cookies3 = structure.cookies) == null ? void 0 : (_structure$cookies3$a = _structure$cookies3.accountData) == null ? void 0 : _structure$cookies3$a.id;
  var pageName = structure == null ? void 0 : structure.name;
  if (!showcaseArr || !Array.isArray(showcaseArr)) return [];
  var parsedData = [];
  showcaseArr.forEach(function (showcase, index) {
    var _structure$config, _structure$config2;
    var button = showcase.button,
      designTokenId = showcase.designTokenId,
      products = showcase.products,
      title = showcase.title;
    if (!Array.isArray(products) || !(products == null ? void 0 : products.length)) {
      return;
    }
    var product = products[0];
    var productId = product.id,
      image = product.image,
      minimumOrderQuantity = product.minimumOrderQuantity,
      path = product.path,
      price = product.price,
      restrictions = product.restrictions,
      seller = product.seller,
      shippingTag = product.shippingTag,
      productTitle = product.title;
    var eventData = {
      available: true,
      display: 'gallery',
      index: index,
      list: title,
      listPosition: index,
      origin: 'bob',
      position: index + 1,
      showcase: showcase,
      type: 'recomendacao'
    };
    var _ref2 = button || {},
      buttonAction = _ref2.action,
      buttonLabel = _ref2.label;
    var handleQuickShowcaseClick = function handleQuickShowcaseClick() {
      if (buttonAction == null ? void 0 : buttonAction.includes('add_item_to_cart')) {
        publish('product:addToCart', {
          customer_id: customerId,
          pageName: pageName,
          product: _extends({}, product, {
            shipping: []
          }),
          typeButton: ADD_TO_BAG
        });
        return;
      }
      publish('product:quickClickSelectItem', _extends({
        buttonLabel: buttonLabel,
        customer_id: customerId,
        pageName: pageName,
        trackingUrl: product.url
      }, product, eventData));
    };
    var onShowCaseView = function onShowCaseView() {
      publish('product:quickClickViewItemList', _extends({
        buttonLabel: buttonLabel,
        customer_id: customerId,
        pageName: pageName
      }, product, eventData));
    };
    parsedData.push({
      button: button,
      cartExtensions: cartExtensions,
      cartUrl: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cartUrl,
      clickEvent: handleQuickShowcaseClick,
      designTokenId: designTokenId,
      image: image,
      location: structure == null ? void 0 : structure.location,
      minimumOrderQuantity: minimumOrderQuantity,
      path: path,
      price: price,
      product: product,
      productId: productId,
      productTitle: productTitle,
      restrictions: restrictions,
      salesChannelId: structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.salesChannelId,
      sellerId: seller.id,
      shippingTag: shippingTag,
      title: title,
      viewEvent: onShowCaseView
    });
  });
  return parsedData;
};
import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import StereoCheckbox from '@magalu/stereo-ui/atoms/Checkbox';
import StereoHorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Checkbox = styled(StereoCheckbox).attrs(function (props) {
  return _extends({
    position: 'relative'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  input {\n    min-width: 20px;\n  }\n"])));
export var HorizontalRule = styled(StereoHorizontalRule).attrs(function (props) {
  return _extends({}, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: 12px 4px 0px;\n  width: 100%;\n\n  ", " {\n    margin: 16px 4px 0px;\n    width: auto;\n  }\n"])), getMediaQuery('medium'));
export var RecommendedContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-end',
    flexDirection: 'column'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding-top: 12px;\n  padding-right: 16px;\n  visibility: ", ";\n"])), function (props) {
  return (props == null ? void 0 : props.recommended) ? 'visible' : 'hidden';
});
export var RecommendedText = styled(Text).attrs(function (props) {
  return _extends({
    backgroundColor: 'primary.base',
    color: 'white'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-radius: 7px;\n  font-size: 14px;\n  font-weight: 500;\n  padding: 2px 6px;\n"])));
export var Wrapper = styled(Flex).attrs(function (props) {
  return _extends({
    bg: 'background.white',
    borderRadius: 8,
    color: 'greyishBrown',
    flexDirection: 'column'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import { StrikeText } from './ServicesOfferPriceAndInstallments.styles';
var ServicesOfferPriceAndInstallments = function ServicesOfferPriceAndInstallments(_ref) {
  var title = _ref.title,
    subtitle = _ref.subtitle,
    pretitle = _ref.pretitle;
  return React.createElement(Box, {
    "data-testid": "price-installments-container"
  }, React.createElement(Text, {
    color: "gray",
    textAlign: "left",
    fontSize: 1,
    "data-testid": "pretitle-text"
  }, pretitle && React.createElement(React.Fragment, null, 'de ', React.createElement(StrikeText, {
    color: "gray",
    as: "span"
  }, pretitle))), React.createElement(Text, {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 2,
    "data-testid": "title-text",
    padding: "6px 0px"
  }, title.text, React.createElement(Text, {
    fontWeight: "bold",
    as: "span"
  }, title.bold)), React.createElement(Text, {
    color: "gray",
    textAlign: "left",
    fontWeight: "500",
    fontSize: 1,
    "data-testid": "subtitle-text"
  }, subtitle));
};
process.env.NODE_ENV !== "production" ? ServicesOfferPriceAndInstallments.propTypes = {
  pretitle: string,
  subtitle: string.isRequired,
  title: shape({
    bold: string,
    text: string
  }).isRequired
} : void 0;
ServicesOfferPriceAndInstallments.defaultProps = {
  pretitle: undefined
};
ServicesOfferPriceAndInstallments.ssr = true;
export default ServicesOfferPriceAndInstallments;
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import PageLoading from '@magalu/stereo-ui/atoms/PageLoading';
import { usePublisher } from '@magalu/mixer-publisher';
var Loader = function Loader() {
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    loading = _useState2[0],
    setLoading = _useState2[1];
  var handleRouteChangeStart = function handleRouteChangeStart() {
    setLoading(true);
  };
  var handleRouteChangeComplete = function handleRouteChangeComplete() {
    setLoading(false);
  };
  var handleRouteChangeError = function handleRouteChangeError() {
    setLoading(false);
  };
  useEffect(function () {
    subscribe('page:loading', setLoading);
    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handleRouteChangeError);
    return function () {
      unsubscribe('page:loading', setLoading);
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, []);
  if (loading) {
    return React.createElement("div", {
      "data-testid": "loading"
    }, React.createElement(PageLoading, null));
  }
  return React.createElement(React.Fragment, null);
};
Loader.ssr = false;
export default Loader;
import React from 'react';
var ReclameAqui = function ReclameAqui() {
  return React.createElement("script", {
    defer: true,
    fetchpriority: "low",
    id: "ra-embed-reputation",
    src: "https://s3.amazonaws.com/raichu-beta/selos/bundle.js",
    "data-id": "MjkyNjY6bWFnYXppbmUtbHVpemEtbG9qYS1vbmxpbmU=",
    "data-target": "reputation-ra",
    "data-model": "2"
  });
};
ReclameAqui.ssr = true;
export default ReclameAqui;
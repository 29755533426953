import { useEffect } from 'react';
import { removeCookie, cookiesConstants } from '@magalu/mixer-utils';
var useValidationCookie = function useValidationCookie(cartId, cookieDomain) {
  var ML2_SID = cookiesConstants.ML2_SID;
  var isCartIdInvalid = cartId === null || cartId === 'null';
  useEffect(function () {
    if (isCartIdInvalid) {
      removeCookie(ML2_SID, cookieDomain, '/');
    }
  }, [cartId, cookieDomain]);
};
export default useValidationCookie;
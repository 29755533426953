import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Box, Item } from '@magalu/stereo-ui/atoms';
import SidebarLoginHeading from './SidebarLoginHeading';
var SidebarHeading = function SidebarHeading(_ref) {
  var _data$helpers;
  var data = _ref.data,
    cartUrl = _ref.cartUrl,
    domain = _ref.domain,
    accountData = _ref.accountData,
    storeName = _ref.storeName,
    onMenuHeaderClick = _ref.onMenuHeaderClick,
    promoterData = _ref.promoterData,
    theme = _ref.theme;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    loggedInfo = _useState2[0],
    setLoggedInfo = _useState2[1];
  useEffect(function () {
    if (promoterData == null ? void 0 : promoterData.store) {
      setLoggedInfo({
        label: data.promoterLabel,
        url: data.loggedUrl
      });
    } else {
      setLoggedInfo({
        label: data.label,
        url: data.customerLoggedUrl
      });
    }
  }, [data, promoterData]);
  return React.createElement(Box, {
    bg: "background.primary",
    "data-testid": "sidebar-header",
    mb: 2
  }, !(accountData == null ? void 0 : accountData.name) && !(promoterData == null ? void 0 : promoterData.store) ? React.createElement(SidebarLoginHeading, {
    storeName: storeName,
    theme: theme,
    cartUrl: cartUrl,
    domain: domain
  }) : React.createElement(React.Fragment, null, React.createElement(Item, {
    scale: "medium",
    endIcon: "ChevronRight",
    fontWeight: "medium",
    variant: "primary",
    as: "a",
    href: loggedInfo == null ? void 0 : loggedInfo.url,
    rel: data == null ? void 0 : data.rel
  }, loggedInfo == null ? void 0 : loggedInfo.label), !(promoterData == null ? void 0 : promoterData.store) && (accountData == null ? void 0 : accountData.name) && React.createElement(Box, {
    bg: "background.primary"
  }, data == null ? void 0 : (_data$helpers = data.helpers) == null ? void 0 : _data$helpers.map(function (item) {
    return React.createElement(Item, {
      key: item == null ? void 0 : item.url,
      href: item == null ? void 0 : item.url,
      rel: item == null ? void 0 : item.rel,
      color: "white",
      startIcon: item == null ? void 0 : item.id,
      onClick: function onClick(e) {
        return onMenuHeaderClick(e, item);
      }
    }, item == null ? void 0 : item.label);
  }))));
};
SidebarHeading.defaultProps = {
  accountData: {},
  cartUrl: '',
  domain: '',
  promoterData: {},
  storeName: '',
  theme: {}
};
process.env.NODE_ENV !== "production" ? SidebarHeading.propTypes = {
  accountData: shape({}),
  cartUrl: string,
  data: shape({
    helpers: arrayOf(shape({
      id: string.isRequired,
      label: string.isRequired,
      url: string.isRequired
    })).isRequired,
    id: string,
    label: string.isRequired,
    loggedLabel: string,
    loggedUrl: string,
    url: string.isRequired
  }).isRequired,
  domain: string,
  onMenuHeaderClick: func.isRequired,
  promoterData: shape({}),
  storeName: string,
  theme: shape({})
} : void 0;
export default SidebarHeading;
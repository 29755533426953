import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
var _excluded = ["buildHref"];
import React from 'react';
import { shape, bool, arrayOf, string } from 'prop-types';
import StereoToolBar from '@magalu/stereo-ui/templates/Toolbar';
import { getCurrentPath, filtersParser, filterCountByType, urlCleanFilters, routePush, filterByDelivery, hasSomeEqualProp } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { ToolbarContainer } from './ToolBar.styles';
var ToolBar = function ToolBar(_ref) {
  var _structure$route;
  var data = _ref.data,
    _ref$static = _ref["static"],
    initialDisplay = _ref$static.initialDisplay,
    _ref$static$showFilte = _ref$static.showFilters,
    showFilters = _ref$static$showFilte === void 0 ? true : _ref$static$showFilte,
    _ref$static$showSort = _ref$static.showSort,
    showSort = _ref$static$showSort === void 0 ? true : _ref$static$showSort,
    _ref$static$customCat = _ref$static.customCategoryFilter,
    customCategoryFilter = _ref$static$customCat === void 0 ? false : _ref$static$customCat,
    _ref$static$removeFil = _ref$static.removeFilters,
    removeFilters = _ref$static$removeFil === void 0 ? [] : _ref$static$removeFil,
    _ref$static$sortLabel = _ref$static.sortLabel,
    sortLabel = _ref$static$sortLabel === void 0 ? 'Ordenar' : _ref$static$sortLabel,
    _ref$static$uniqueFil = _ref$static.uniqueFilters,
    uniqueFilters = _ref$static$uniqueFil === void 0 ? [] : _ref$static$uniqueFil,
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var query = structure.query;

  var _ref2 = (data == null ? void 0 : data.search) || {},
    sorts = _ref2.sorts;
  var onChangeSort = function onChangeSort(sort) {
    var sortType = sort.type,
      sortOrientation = sort.orientation;
    publish('productlist:sort:change', {
      sort: sort
    });
    var path = getCurrentPath({
      page: 1,
      sortOrientation: sortOrientation,
      sortType: sortType
    });
    routePush({
      path: path,
      spa: true
    });
  };

  var _ref3 = (data == null ? void 0 : data.search) || {},
    filters = _ref3.filters;
  var filteredBySidePosition = filters == null ? void 0 : filters.map(function (item) {
    if (filterByDelivery(item)) {
      var _item$values;
      var filteredValues = item == null ? void 0 : (_item$values = item.values) == null ? void 0 : _item$values.filter(function (value) {
        var _value$position;
        return value == null ? void 0 : (_value$position = value.position) == null ? void 0 : _value$position.includes('side');
      });
      return _extends({}, item, {
        values: filteredValues
      });
    }
    return item;
  });
  var sortFilters = function sortFilters() {
    var _data$search;
    var searchFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var filterBySlug = searchFilters.reduce(function (acc, filter) {
      return _extends({}, acc, _defineProperty({}, filter.slug, filter));
    }, {});
    var baseFilters = (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.filters) || [];
    return baseFilters.reduce(function (acc, _ref4) {
      var _filterBySlug$slug, _filterBySlug$slug$va, _filterBySlug$slug2;
      var slug = _ref4.slug;
      return filterBySlug[slug] && (((_filterBySlug$slug = filterBySlug[slug]) == null ? void 0 : (_filterBySlug$slug$va = _filterBySlug$slug.values) == null ? void 0 : _filterBySlug$slug$va.length) || ((_filterBySlug$slug2 = filterBySlug[slug]) == null ? void 0 : _filterBySlug$slug2.componentType) === 'priceRange') ? [].concat(_toConsumableArray(acc), [filterBySlug[slug]]) : acc;
    }, []);
  };
  var buildFilterFilters = function buildFilterFilters() {
    var searchFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var _filtersParser = filtersParser({
        customCategoryFilter: customCategoryFilter,
        data: data,
        filters: searchFilters,
        query: query,
        removeFilters: removeFilters,
        structure: structure
      }),
      attributeFilters = _filtersParser.attributeFilters,
      reviewFilters = _filtersParser.reviewFilters,
      categoryFilters = _filtersParser.categoryFilters,
      priceFilters = _filtersParser.priceFilters;
    var newParsedFilters = attributeFilters.map(function (filter) {
      var componentType = uniqueFilters.some(function (uniqueFilterConfig) {
        return hasSomeEqualProp(filter, uniqueFilterConfig);
      }) ? 'unique' : 'multiple';
      return _extends({}, filter, {
        componentType: componentType
      });
    });
    if (reviewFilters) {
      reviewFilters.componentType = 'unique';
      newParsedFilters.push(reviewFilters);
    }
    if (categoryFilters) {
      categoryFilters.componentType = 'hierarchical';
      newParsedFilters.push(categoryFilters);
    }
    if (priceFilters) {
      priceFilters.componentType = 'priceRange';
      newParsedFilters.push(priceFilters);
    }
    return sortFilters(newParsedFilters);
  };
  var parsedFilters = buildFilterFilters(filteredBySidePosition);
  var currentSidebarFilters = ((structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters) || []).filter(function (_ref5) {
    var origin = _ref5.origin;
    return origin !== 'url';
  });
  var filtersCount = filterCountByType(currentSidebarFilters);
  var onFilterClick = function onFilterClick(action) {
    if (action) {
      publish('productlist:filter:open');
    } else {
      publish('productlist:filter:close');
    }
  };
  var onFiltersClear = function onFiltersClear() {
    publish('productlist:filter:clean');
    var asPath = structure.asPath,
      rawPath = structure.rawPath;
    var path = urlCleanFilters(asPath, rawPath, query);
    routePush({
      path: path,
      spa: true
    });
  };
  var onFilterItemClick = function onFilterItemClick() {
    var custom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return function (e, filter) {
      e.preventDefault();
      publish('productlist:filter:click', _extends({}, filter, {
        custom: custom
      }));
      routePush({
        path: filter == null ? void 0 : filter.href,
        spa: true
      });
    };
  };
  var onFilterPriceApply = function onFilterPriceApply(e, _ref6, filter) {
    var min = _ref6.min,
      max = _ref6.max;
    e.preventDefault();
    var buildHref = filter.buildHref,
      appliedFilter = _objectWithoutProperties(filter, _excluded);
    var applyPriceHref = buildHref(max, min);
    publish('productlist:filter:click', _extends({}, appliedFilter, {
      selectedMax: max,
      selectedMin: min
    }));
    routePush({
      path: applyPriceHref,
      spa: true
    });
  };
  var onFilterShowDetail = function onFilterShowDetail(filter) {
    publish('productlist:filter:showdetail', filter);
  };
  return React.createElement(ToolbarContainer, null, React.createElement(StereoToolBar, {
    defaultDisplayMode: initialDisplay,
    sortOptions: showSort && sorts,
    sortLabel: sortLabel,
    showSelectedOptionLabel: !!(query == null ? void 0 : query.sortType),
    onChangeSort: onChangeSort,
    filters: parsedFilters,
    showFilters: showFilters && parsedFilters,
    filtersCount: filtersCount,
    onFilterClick: onFilterClick,
    onFiltersClear: onFiltersClear,
    onFilterItemClick: {
      attributeFilter: onFilterItemClick(),
      categoryFilter: onFilterItemClick(true),
      priceFilter: onFilterPriceApply,
      reviewFilter: onFilterItemClick()
    },
    onFilterTypeClick: onFilterShowDetail
  }));
};
ToolBar.ssr = true;
ToolBar.defaultProps = {
  data: {},
  "static": {
    customCategoryFilter: false,
    initialDisplay: 'gallery',
    removeFilters: [],
    showFilters: true,
    showSort: true,
    sortLabel: 'Ordenar'
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? ToolBar.propTypes = {
  data: shape({}),
  "static": shape({
    customCategoryFilter: bool,
    initialDisplay: string,
    removeFilters: arrayOf(string),
    showFilters: bool,
    showSort: bool
  }),
  structure: shape({})
} : void 0;
export default withLayoutProps(ToolBar);
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { slug } from '@magalu/mixer-utils';
import { BenefitText, BenefitPriceText, IconCheck, IconPrize } from './ServicesInfo.styles';
var Benefits = function Benefits(_ref) {
  var benefit = _ref.benefit;
  var benefitLength = benefit == null ? void 0 : benefit.length;
  return React.createElement(React.Fragment, null, benefit == null ? void 0 : benefit.map(function (_ref2, index) {
    var description = _ref2.description,
      iconName = _ref2.iconName;
    var isLastItem = index + 1 < benefitLength;
    return isLastItem ? React.createElement(BenefitText, {
      key: slug(description + index),
      "data-testid": "benefit-container",
      pr: 2
    }, React.createElement(IconCheck, {
      name: iconName !== null && iconName !== void 0 ? iconName : 'Done',
      "data-testid": "benefit-icon"
    }), description) : React.createElement(BenefitPriceText, {
      key: slug(description + index),
      "data-testid": "benefit-container",
      pr: 3
    }, React.createElement(IconPrize, {
      name: iconName !== null && iconName !== void 0 ? iconName : 'Done',
      "data-testid": "benefit-icon"
    }), description);
  }));
};
Benefits.defaultProps = {
  benefit: [{
    description: '',
    iconName: ''
  }]
};
process.env.NODE_ENV !== "production" ? Benefits.propTypes = {
  benefit: arrayOf(shape({
    description: string,
    iconName: string
  }))
} : void 0;
export default Benefits;
import getOfferPriceAndInstallments from './getOfferPriceAndInstallments';
var getOffersInput = function getOffersInput(_ref) {
  var installments = _ref.installments,
    publishServiceClickEvent = _ref.publishServiceClickEvent,
    service = _ref.service,
    serviceIdToOfferId = _ref.serviceIdToOfferId,
    onSelectedServicesIdsChanged = _ref.onSelectedServicesIdsChanged,
    onServicesSelectedOfferChange = _ref.onServicesSelectedOfferChange;
  return {
    options: service.offers.map(function (offer) {
      var year = offer.description.replace("".concat(service.description, " "), '').slice(0, 2) / 12;
      return {
        checked: serviceIdToOfferId[service.id] === offer.id,
        onChange: function onChange(offerId) {
          var checked = !!offerId;
          onServicesSelectedOfferChange(service.id, offerId);
          publishServiceClickEvent({
            checked: checked,
            service: service
          });
          onSelectedServicesIdsChanged(service.id, checked);
        },
        recommended: !!(offer == null ? void 0 : offer.recommended),
        selectedOfferPrice: getOfferPriceAndInstallments({
          installments: installments,
          offer: offer,
          service: service
        }),
        title: "".concat(year, " ").concat(year > 1 ? 'anos' : 'ano', " de ").concat(service.title),
        value: offer.id
      };
    }),
    value: serviceIdToOfferId[service.id]
  };
};
export default getOffersInput;
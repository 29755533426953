import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["enableHover", "imageHeight", "imageWidth", "limit", "showDiscount", "showDiscountOnlyCashInfo", "showInCashLabel", "allowEmptyRating", "attributeStyle", "colorMinLength", "gridGap"];
import React from 'react';
import { bool, string, number, shape } from 'prop-types';
import ProductListModule from '@magalu/stereo-ui/organisms/ProductList';

import withLayoutProps from '../../../hocs/withLayoutProps';

import useProductList from './useProductList';
var offerTimerProps = {
  bg: 'primary'
};
var ProductList = function ProductList(_ref) {
  var _structure$theme;
  var data = _ref.data,
    _ref$static = _ref["static"];
  _ref$static = _ref$static === void 0 ? {} : _ref$static;
  var _ref$static$enableHov = _ref$static.enableHover,
    enableHover = _ref$static$enableHov === void 0 ? false : _ref$static$enableHov,
    imageHeight = _ref$static.imageHeight,
    imageWidth = _ref$static.imageWidth,
    _ref$static$limit = _ref$static.limit,
    limit = _ref$static$limit === void 0 ? 0 : _ref$static$limit,
    showDiscount = _ref$static.showDiscount,
    showDiscountOnlyCashInfo = _ref$static.showDiscountOnlyCashInfo,
    _ref$static$showInCas = _ref$static.showInCashLabel,
    showInCashLabel = _ref$static$showInCas === void 0 ? true : _ref$static$showInCas,
    _ref$static$allowEmpt = _ref$static.allowEmptyRating,
    allowEmptyRating = _ref$static$allowEmpt === void 0 ? false : _ref$static$allowEmpt,
    attributeStyle = _ref$static.attributeStyle,
    colorMinLength = _ref$static.colorMinLength,
    _ref$static$gridGap = _ref$static.gridGap,
    gridGap = _ref$static$gridGap === void 0 ? 2 : _ref$static$gridGap,
    staticProps = _objectWithoutProperties(_ref$static, _excluded),
    structure = _ref.structure;
  var _useProductList = useProductList({
      data: data,
      "static": staticProps,
      structure: structure
    }),
    display = _useProductList.display,
    domain = _useProductList.domain,
    handleAddToWishlist = _useProductList.handleAddToWishlist,
    handleListShowMore = _useProductList.handleListShowMore,
    handleProductClick = _useProductList.handleProductClick,
    handleProductView = _useProductList.handleProductView,
    handleThumbnailClick = _useProductList.handleThumbnailClick,
    isEmptyList = _useProductList.isEmptyList,
    products = _useProductList.products,
    showWishilistButton = _useProductList.showWishilistButton,
    ref = _useProductList.ref;
  return React.createElement(ProductListModule, {
    ref: ref,
    key: products,
    enableHover: enableHover,
    showInCashInfo: showInCashLabel,
    onClick: handleProductClick,
    onClickLoadMore: handleListShowMore,
    data: products,
    displayMode: display,
    domain: domain,
    onProductView: handleProductView,
    partial: limit,
    notFound: isEmptyList,
    showWishBtn: showWishilistButton,
    onClickWishBtn: handleAddToWishlist,
    imageWidth: imageWidth,
    imageHeight: imageHeight,
    showDiscount: showDiscount,
    showDiscountOnlyCashInfo: showDiscountOnlyCashInfo,
    allowEmptyRating: allowEmptyRating,
    onThumbnailClick: handleThumbnailClick,
    colorMinLength: colorMinLength,
    attributeStyle: attributeStyle,
    offerTimerProps: (structure == null ? void 0 : (_structure$theme = structure.theme) == null ? void 0 : _structure$theme.name) === 'magazineluiza-bf' ? offerTimerProps : undefined,
    gridGap: gridGap
  });
};
process.env.NODE_ENV !== "production" ? ProductList.propTypes = {
  data: shape({}),
  "static": shape({
    allowEmptyRating: bool,
    context: shape({}),
    display: string,
    domain: string,
    gridGap: number,
    inCashDescription: string,
    limit: number,
    origin: string,
    pageSize: number,
    position: number,
    showDiscount: bool,
    showDiscountOnlyCashInfo: bool,
    showInCashLabel: bool,
    showWishBtn: bool,
    spa: bool,
    type: string
  }),
  structure: shape({})
} : void 0;
ProductList.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
ProductList.ssr = true;
ProductList.displayName = 'ProductList';
ProductList.dataSource = {
  search: {
    query: 'SearchQuery'
  }
};
export default withLayoutProps(ProductList);

export var findCheckedIndex = function findCheckedIndex(options) {
  var selected = options.findIndex(function (option) {
    return option.checked;
  });
  return selected !== -1 ? selected : options.findIndex(function (option) {
    return option.recommended;
  });
};
export var findMaxIndexCheckedOrRecomended = function findMaxIndexCheckedOrRecomended(options) {
  var checkedIndex = options.findIndex(function (option) {
    return option.checked;
  });
  var recommendedIndex = options.findIndex(function (option) {
    return option.recommended;
  });
  return Math.max(checkedIndex, recommendedIndex);
};

export var optionsHideCardsCollapsed = function optionsHideCardsCollapsed(isOpen, selectedIndex, options) {
  if (!isOpen) {
    return selectedIndex !== -1 ? [options[selectedIndex]] : [options[0]];
  }
  return options;
};

export var optionsShowCardsCollapsed = function optionsShowCardsCollapsed(isOpen, selectedIndex, options, serviceKey, visibleCards) {
  if (visibleCards[serviceKey]) {
    var valuesToShow = selectedIndex > 0 ? selectedIndex + 1 : visibleCards[serviceKey];
    return options == null ? void 0 : options.filter(function (option, index) {
      return index < valuesToShow || isOpen || option.checked;
    });
  }
  return options;
};
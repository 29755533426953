import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';

var getOfferPriceAndInstallments = function getOfferPriceAndInstallments() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var title = {
    bold: '',
    text: ''
  };

  var pretitle = '';
  var subtitle = '';
  try {
    var installments = input.installments,
      offer = input.offer;
    var newInstallments = !installments || installments <= 0 ? 1 : installments;
    title.text = "".concat(newInstallments, "x de ");
    title.bold = "".concat(currencyFormatter({
      value: offer.price / newInstallments
    }), " sem juros");
    pretitle = offer.priceFrom ? currencyFormatter({
      value: offer.priceFrom
    }) : undefined;
    subtitle = "".concat(offer.priceFrom ? 'por ' : '').concat(currencyFormatter({
      value: offer.price
    }), " no pix");
  } catch (_unused) {
    title.text = undefined;
    title.bold = undefined;
    pretitle = undefined;
    subtitle = undefined;
  }
  return {
    pretitle: pretitle,
    subtitle: subtitle,
    title: title
  };
};
export default getOfferPriceAndInstallments;
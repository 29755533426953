import React from 'react';
import { arrayOf, shape, string, oneOf } from 'prop-types';

import Card from '@magalu/stereo-ui/atoms/Card';
import Text from '@magalu/stereo-ui/atoms/Text';
import Link from '@magalu/stereo-ui/atoms/Link';

import CarouselStereo from '@magalu/stereo-ui/molecules/Carousel';

import { routePush, parseDateTime } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { createCustomUrl } from '../variations.utils';
function Carousel(_ref) {
  var cardTheme = _ref.cardTheme,
    posts = _ref.posts,
    viewport = _ref.viewport,
    titleAs = _ref.titleAs,
    customBasePath = _ref.customBasePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePostClick = function handlePostClick(categoryId, e, title, slug, postId) {
    e.preventDefault();
    var recomendationLayout = 'Carousel';
    routePush({
      path: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(postId, "/"),
      spa: true
    });
    publish('blogpost:click', {
      categoryId: categoryId,
      recomendationLayout: recomendationLayout,
      title: title
    });
  };
  var handleSubCategoryPostClick = function handleSubCategoryPostClick(category, subcategory, e) {
    var subcategoryUrl = createCustomUrl(category, subcategory);
    e.stopPropagation();
    routePush({
      path: subcategoryUrl,
      spa: true
    });
  };
  return React.createElement(CarouselStereo, {
    "data-testid": "carousel-variation",
    auto: false,
    showControls: false,
    duration: 400,
    gutter: 15,
    interval: 3000,
    insideControls: true,
    showArrowControls: true,
    slidesPerView: viewport === 'desktop' ? 2 : 1
  }, posts == null ? void 0 : posts.map(function (_ref2) {
    var _image$urls, _parseDateTime;
    var id = _ref2.id,
      image = _ref2.image,
      title = _ref2.title,
      categoryId = _ref2.categoryId,
      categoryTitle = _ref2.categoryTitle,
      updatedAt = _ref2.updatedAt,
      subtitle = _ref2.subtitle,
      slug = _ref2.slug,
      category = _ref2.category,
      subcategory = _ref2.subcategory;
    return React.createElement(Card, {
      key: id,
      as: "a",
      href: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
      backgroundColor: cardTheme,
      enableHover: true,
      "data-testid": "carousel-card",
      gridTemplateRows: ['250px 1f r', '400px 1fr', '450px 1fr'],
      height: ['480px', '650px', '700px'],
      gridTemplateColumns: "1fr",
      onClick: function onClick(e) {
        return handlePostClick(categoryId, e, title, slug, id);
      }
    }, React.createElement(Card.Image, {
      src: "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=fit-in/{w}x"),
      alt: image.alt,
      srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
      width: viewport === 'mobile' ? 380 : 660,
      imageHeight: ['250px', '400px', '450px']
    }), React.createElement(Card.Body, null, React.createElement(Card.Title, {
      forwardedAs: titleAs,
      truncate: true,
      lines: 2,
      fontSize: [4, 5, 5],
      lineHeight: ['display', 'input', 'input']
    }, title), React.createElement(Card.Subtitle, {
      fontSize: [2, 2, 2]
    }, Boolean(updatedAt) && "".concat((_parseDateTime = parseDateTime(updatedAt)) == null ? void 0 : _parseDateTime.getShortMonthYear, " | "), React.createElement(Text, {
      fontWeight: "bold",
      color: "inherit",
      display: "inline-block"
    }, categoryTitle)), React.createElement(Card.Text, {
      truncate: true,
      lines: 4,
      fontSize: [2, 3, 3]
    }, subtitle), subcategory && React.createElement(Link, {
      p: "8px 0",
      color: "inherit",
      fontWeight: "bold",
      "data-testid": "blog-subCategory-link",
      href: createCustomUrl(category, subcategory),
      onClick: function onClick(e) {
        return handleSubCategoryPostClick(category, subcategory, e);
      }
    }, subcategory.title)));
  }));
}
process.env.NODE_ENV !== "production" ? Carousel.propTypes = {
  cardTheme: string,
  customBasePath: string,
  posts: arrayOf(shape({})).isRequired,
  titleAs: string,
  viewport: oneOf(['mobile', 'desktop'])
} : void 0;
Carousel.defaultProps = {
  cardTheme: 'background.light',
  customBasePath: '',
  titleAs: 'h3',
  viewport: 'mobile'
};
export default Carousel;
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import getConfig from 'next/config';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import QuickClickCard from '../QuickClickCard';
import { useShowcase } from '@magalu/mixer-graphql';
import { getCustomerId, hasMinimalRecommendations, parseQuickClickData } from './QuickClickShowcase.utils';
var QuickClickShowcase = function QuickClickShowcase(_ref) {
  var _publicRuntimeConfig$;
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var _getConfig = getConfig(),
    publicRuntimeConfig = _getConfig.publicRuntimeConfig;
  var timeout = publicRuntimeConfig == null ? void 0 : (_publicRuntimeConfig$ = publicRuntimeConfig.recommendation) == null ? void 0 : _publicRuntimeConfig$.timeout;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    showcases = _useState2[0],
    setShowcases = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var customerId = getCustomerId(structure);
  var _ref2 = (structure == null ? void 0 : structure.cookies) || {},
    location = _ref2.location,
    trackId = _ref2.trackId;
  var extensions = staticProps.extensions,
    maxLengthShowcases = staticProps.maxLengthShowcases,
    minLengthShowcases = staticProps.minLengthShowcases,
    pageId = staticProps.page,
    partnerId = staticProps.partnerId,
    placeId = staticProps.placement,
    styles = staticProps.styles;
  var _useShowcase = useShowcase({
      customerId: customerId,
      options: {
        timeout: timeout
      },
      pageId: pageId,
      partnerId: partnerId,
      placeId: placeId,
      trackId: trackId,
      withButtonAction: true,
      zipCode: location == null ? void 0 : location.zipCode
    }),
    recommendation = _useShowcase.recommendation;
  var shouldRenderShowcase = hasMinimalRecommendations(recommendation, minLengthShowcases);
  useEffect(function () {
    if (shouldRenderShowcase) {
      setShowcases(parseQuickClickData({
        cartExtensions: extensions,
        publish: publish,
        showcaseArr: recommendation,
        structure: structure
      }));
    }
  }, [recommendation, shouldRenderShowcase, trackId]);
  return React.createElement(React.Fragment, null, shouldRenderShowcase ? React.createElement(Flex, _extends({
    "data-testid": "quickclick-showcase"
  }, styles), showcases == null ? void 0 : showcases.slice(0, maxLengthShowcases).map(function (showcase) {
    return React.createElement(QuickClickCard, _extends({
      key: "quickclick-card-".concat(showcase.productId)
    }, showcase));
  })) : null);
};
process.env.NODE_ENV !== "production" ? QuickClickShowcase.propTypes = {
  "static": shape({
    extensions: oneOfType([arrayOf(shape({})), bool]),
    maxLengthShowcases: number,
    minLengthShowcases: number,
    page: string.isRequired,
    partnerId: string.isRequired,
    placement: string.isRequired,
    styles: shape({})
  }),
  structure: shape({})
} : void 0;
QuickClickShowcase.defaultProps = {
  "static": {
    extensions: false,
    maxLengthShowcases: 10,
    minLengthShowcases: 5,
    styles: {}
  },
  structure: {}
};
QuickClickShowcase.ssr = true;
export default withLayoutProps(QuickClickShowcase);
import React from 'react';
import { string } from 'prop-types';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { List, ListContainer } from './SeoRelatedLinkList.styles';
var SeoRelatedLinkList = function SeoRelatedLinkList(_ref) {
  var _data$seoRelatedLink, _data$seoRelatedLink2;
  var data = _ref.data,
    titleText = _ref["static"].titleText;
  var onLinkClick = function onLinkClick(e, href) {
    e.preventDefault();
    routePush({
      path: href,
      spa: true
    });
  };
  return ((_data$seoRelatedLink = data.seoRelatedLink) == null ? void 0 : _data$seoRelatedLink.length) > 0 ? React.createElement(ListContainer, null, titleText && React.createElement(List.Header, {
    "data-testid": "link-list-header",
    p: " 0 8px !important"
  }, titleText), React.createElement(List, {
    variant: "light",
    "data-testid": "link-list"
  }, (_data$seoRelatedLink2 = data.seoRelatedLink) == null ? void 0 : _data$seoRelatedLink2.map(function (_ref2) {
    var label = _ref2.label,
      href = _ref2.href;
    return React.createElement(List.Item, {
      as: "a",
      scale: "small",
      key: 1,
      href: href,
      onClick: function onClick(e) {
        return onLinkClick(e, href);
      }
    }, label);
  }))) : null;
};
process.env.NODE_ENV !== "production" ? SeoRelatedLinkList.propTypes = {
  data: {},
  "static": {
    titleText: string
  }
} : void 0;
SeoRelatedLinkList.defaultProps = {
  data: {},
  "static": {}
};
SeoRelatedLinkList.ssr = true;
export default withLayoutProps(SeoRelatedLinkList);
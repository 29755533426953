import _extends from "@babel/runtime/helpers/esm/extends";
import { slug } from '@magalu/mixer-utils';
import viewItemList from './viewItemList';
import selectItem from './selectItem';
var quickClickSelectItem = function quickClickSelectItem(data) {
  var _selectItem, _data$listPosition;
  var buttonLabel = data == null ? void 0 : data.buttonLabel;
  var variationId = decodeURIComponent(data.url).match(/\/p\/([^/]+)/)[1];
  var parsedData = _extends({}, data, {
    variationId: variationId
  });
  return _extends({}, selectItem(parsedData), {
    eventLabel: "gallery:".concat(slug((data == null ? void 0 : data.list) || 'null'), ":").concat(buttonLabel ? "".concat(slug(buttonLabel), ":") : '', "slot-").concat(data.listPosition),
    items: [_extends({}, (_selectItem = selectItem(parsedData)) == null ? void 0 : _selectItem.items[0], {
      dimension74: '0:',
      dimension107: (_data$listPosition = data == null ? void 0 : data.listPosition) !== null && _data$listPosition !== void 0 ? _data$listPosition : null
    })]
  });
};
var quickClickViewItemList = function quickClickViewItemList(data) {
  var _viewItemList, _data$listPosition2;
  var buttonLabel = data == null ? void 0 : data.buttonLabel;
  var variationId = decodeURIComponent(data.url).match(/\/p\/([^/]+)/)[1];
  var parsedData = _extends({}, data, {
    variationId: variationId
  });
  return _extends({}, viewItemList(parsedData), {
    eventLabel: "gallery:".concat(slug((data == null ? void 0 : data.list) || 'null'), ":").concat(buttonLabel ? "".concat(slug(buttonLabel), ":") : '', "slot-").concat(data.listPosition),
    items: [_extends({}, (_viewItemList = viewItemList(parsedData)) == null ? void 0 : _viewItemList.items[0], {
      dimension74: '0:',
      dimension107: (_data$listPosition2 = data == null ? void 0 : data.listPosition) !== null && _data$listPosition2 !== void 0 ? _data$listPosition2 : null
    })]
  });
};
export { quickClickSelectItem, quickClickViewItemList };
import React from 'react';
import { shape } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import { parseRecordsTitle } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { CountLabel } from './SearchHeader.styles';
var SearchHeader = function SearchHeader(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var countLabel = staticProps.countLabel;
  var _ref2 = (data == null ? void 0 : data.search) || {},
    pagination = _ref2.pagination;
  var recordsTitle = {
    label: countLabel,
    records: pagination == null ? void 0 : pagination.records
  };
  return React.createElement(Flex, {
    alignContent: "space-between"
  }, (pagination == null ? void 0 : pagination.records) && React.createElement(CountLabel, null, parseRecordsTitle(recordsTitle)));
};
process.env.NODE_ENV !== "production" ? SearchHeader.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
SearchHeader.defaultProps = {
  data: {},
  "static": {}
};
SearchHeader.ssr = true;
export default withLayoutProps(SearchHeader);
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { parsePlaceholders } from '@magalu/mixer-structure';
import { replaceDescription } from '@magalu/mixer-utils';
var getAuthorizationTermText = function getAuthorizationTermText(input) {
  var services = input.services,
    selectedServicesIds = input.selectedServicesIds,
    servicesSelectedOfferId = input.servicesSelectedOfferId,
    authorizationTermTextRaw = input.authorizationTermTextRaw;
  var selectedServices = services.filter(function (service) {
    return selectedServicesIds == null ? void 0 : selectedServicesIds.includes(service.id);
  });
  var selectedServicesDescription = selectedServices.map(function (service) {
    var offer = service.offers.find(function (serviceOffer) {
      return serviceOffer.id === servicesSelectedOfferId[service.id];
    }) || {};
    return replaceDescription(offer.description, service.descriptionTac);
  });
  var selectedServicesPrice = selectedServices.reduce(function (acc, service) {
    var offer = service.offers.find(function (serviceOffer) {
      return serviceOffer.id === servicesSelectedOfferId[service.id];
    }) || {};
    return acc + offer.price;
  }, 0);
  var parseDescriptionValue = function parseDescriptionValue(value) {
    var startSentence = (value == null ? void 0 : value.length) > 1 ? 'dos seguros' : 'do seguro';
    var descriptions = value.join(', ').replace(/, ([^,]*)$/, ' e $1').toLowerCase();
    return "".concat(startSentence, " ").concat(descriptions);
  };
  var authorizationTermText = selectedServices.reduce(function (acc, service) {
    var offer = service.offers.find(function (serviceOffer) {
      return serviceOffer.id === servicesSelectedOfferId[service.id];
    }) || {};
    var _offer$wageCompensati = offer.wageCompensation,
      wageCompensation = _offer$wageCompensati === void 0 ? {} : _offer$wageCompensati;
    var amount = wageCompensation.amount,
      percentage = wageCompensation.percentage;
    var chargingTermsWithValues = parsePlaceholders((service == null ? void 0 : service.chargingTerms) || '', {
      wageCompensationAmount: currencyFormatter({
        value: Number(amount || '0')
      }),
      wageCompensationPercentage: "".concat(percentage || '0', "%")
    });
    return "".concat(acc, "<p>&nbsp;</p>").concat(chargingTermsWithValues || '');
  }, authorizationTermTextRaw);
  return parsePlaceholders(authorizationTermText, {
    description: parseDescriptionValue(selectedServicesDescription),
    totalPrice: currencyFormatter({
      value: selectedServicesPrice || 0
    })
  });
};
export default getAuthorizationTermText;
var paymentTypes = {
  boleto: 'bankSlip',
  luiza_ouro: 'luizaCard',
  pix: 'pix',
  visa: 'creditCard'
};
export var mapPaymentMethods = function mapPaymentMethods() {
  var paymentMethods = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return paymentMethods.reduce(function (acc, payment) {
    var paymentKey = paymentTypes[payment.id] || 'creditCard';
    acc[paymentKey] = payment;
    return acc;
  }, {});
};
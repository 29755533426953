import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15;
import styled from 'styled-components';
import { themeGet, getMediaQuery, getBackgroundColor } from '@magalu/stereo-ui-styles';
import { Box, Button as ButtonElement, Flex, Image as ImageElement, Text } from '@magalu/stereo-ui/atoms';
import { OfferTimer as OfferTimerElement } from '@magalu/stereo-ui/molecules';
export var BestPrice = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-weight: 500;\n  margin-right: 4px;\n  font-size: 18px;\n  line-height: 1;\n\n  ", " {\n    font-size: 24px;\n  }\n"])), getMediaQuery('medium'));
export var Button = styled(ButtonElement).attrs(function (props) {
  return _extends({
    color: 'primary',
    variation: 'outline'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-radius: 8px;\n  margin-top: 8px;\n  min-width: 100px;\n  max-height: 30px;\n\n  ", " {\n    font-size: ", "px;\n    margin-top: 16px;\n    max-height: 40px;\n  }\n  > label {\n    font-size: ", "px;\n    text-transform: initial !important; /* Sorry guys, it was really necessary to overwrite the styles */\n    font-weight: 600;\n\n    ", " {\n      font-size: ", "px;\n    }\n  }\n"])), getMediaQuery('medium'), themeGet("fontSizes.0"), themeGet("fontSizes.0"), getMediaQuery('medium'), themeGet("fontSizes.2"));
export var CardContent = styled(Box).attrs(function (props) {
  return _extends({}, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var CardHeader = styled(Box).attrs(function (props) {
  return _extends({}, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var Container = styled(Flex).attrs(function (props) {
  return _extends({}, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: 8px;\n  border: 1px solid #d3dadd;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  min-width: 144px;\n  padding: 8px 8px 12px 8px;\n  width: 100%;\n\n  ", " {\n    max-width: 264px;\n    padding: 16px;\n  }\n\n  &:hover,\n  &:active {\n    border: 1px solid #d3dadd;\n  }\n"])), function (props) {
  return getBackgroundColor('white')(props);
}, getMediaQuery('medium'));
export var Image = styled(ImageElement)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  max-height: 115px;\n  min-height: 78px;\n  object-fit: contain;\n  width: auto;\n\n  ", " {\n    min-height: 167px;\n  }\n"])), getMediaQuery('medium'));
export var ImageContainer = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 4px;\n  padding: 8px;\n  position: relative;\n"])));
export var OfferTimer = styled(OfferTimerElement)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  border-radius: 4px;\n  height: 20px;\n  margin-right: 0;\n  margin-top: 0;\n  padding: 0 3px;\n  & > p {\n    font-size: ", "px;\n    font-weight: ", ";\n  }\n\n  ", " {\n    height: 26px;\n\n    & > p {\n      font-size: ", "px;\n    }\n  }\n\n  & > svg {\n    display: none;\n  }\n"])), themeGet("fontSizes.0"), themeGet("fontWeights.regular"), getMediaQuery('medium'), themeGet("fontSizes.3"));
export var OfferTimerContainer = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var PaymentMethodDescription = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 0,
    fontWeights: 'regular'
  }, props);
})(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  color: #1e2428;\n"])));
export var Price = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  align-items: baseline;\n  color: #1e2428;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 8px;\n\n  ", " {\n    margin-top: 12px;\n  }\n"])), getMediaQuery('medium'));
export var ProductTitle = styled(Text).attrs(function (props) {
  return _extends({}, props);
})(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */\n  -webkit-line-clamp: 2; /* stylelint-disable-line property-no-vendor-prefix */\n  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */\n  font-size: ", "px;\n  line-height: 1.25;\n  margin-top: 12px;\n  max-height: 3em;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: normal;\n"])), themeGet('fontSizes.1'));
export var ShippingDescription = styled(Text).attrs(function () {
  return {
    fontWeight: 'regular'
  };
})(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  color: #0086ff;\n  font-size: ", "px;\n"])), themeGet('fontSizes.0'));
export var ShippingTag = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  margin-top: 8px;\n\n  > p:first-of-type {\n    margin-top: 0;\n  }\n"])));
export var Title = styled(Text)(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  color: #1e2428;\n  font-size: ", "px;\n  font-weight: 600;\n  line-height: 1rem;\n  overflow: hidden;\n\n  ", " {\n    font-size: ", "px;\n  }\n"])), themeGet('fontSizes.0'), getMediaQuery('medium'), themeGet('fontSizes.3'));
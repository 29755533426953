import React from 'react';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ServicesGroup from '../ServicesGroup';
import { ServicesGrid } from './ServicesWrapper.styles';
import { ServicesWrapperPropTypes } from './ServicesWrapper.types';
var ServicesWrapper = function ServicesWrapper(props) {
  var services = props.services;
  return React.createElement(ServicesGrid, {
    "data-testid": "services-grid",
    nCards: services.length
  }, services.map(function (service) {
    return React.createElement(ServicesGroup, {
      key: "services-group-".concat(service.key),
      service: service
    });
  }));
};
process.env.NODE_ENV !== "production" ? ServicesWrapper.propTypes = ServicesWrapperPropTypes : void 0;
export default withLayoutProps(ServicesWrapper);
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { shape } from 'prop-types';
import Select from '@magalu/stereo-ui/atoms/Select';
import { getCurrentPath, routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var SearchOrder = function SearchOrder(_ref) {
  var _structure$route, _structure$route2;
  var data = _ref.data,
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _ref2 = (data == null ? void 0 : data.search) || {},
    sorts = _ref2.sorts;
  var selectedOption = (_structure$route = structure.route) == null ? void 0 : _structure$route.sortType;
  var selectedOrientation = (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.sortOrientation;
  var onChange = function onChange(label) {
    var _sorts$filter = sorts == null ? void 0 : sorts.filter(function (item) {
        return item.label === label;
      }),
      _sorts$filter2 = _slicedToArray(_sorts$filter, 1),
      sort = _sorts$filter2[0];
    if (sort) {
      var sortType = sort.type,
        sortOrientation = sort.orientation;
      publish('productlist:sort:change', {
        sort: sort
      });
      var path = getCurrentPath({
        page: 1,
        sortOrientation: sortOrientation,
        sortType: sortType
      });
      routePush({
        path: path,
        spa: true
      });
    }
  };
  var options = sorts == null ? void 0 : sorts.reduce(function (acc, sort) {
    if (selectedOption && selectedOrientation && sort.type === selectedOption && sort.orientation === selectedOrientation) {
      return [{
        label: sort.label,
        selected: true,
        value: sort.label
      }].concat(_toConsumableArray(acc));
    }
    return [].concat(_toConsumableArray(acc), [{
      label: sort.label,
      selected: false,
      value: sort.label
    }]);
  }, []);
  return options ? React.createElement(Select, {
    desktopMode: true,
    id: "desktop-with-label",
    textLabel: "Ordenar por",
    options: options,
    onChange: onChange
  }) : null;
};
process.env.NODE_ENV !== "production" ? SearchOrder.propTypes = {
  data: shape({}),
  structure: shape({})
} : void 0;
SearchOrder.defaultProps = {
  data: {},
  structure: {}
};
SearchOrder.ssr = true;
export default withLayoutProps(SearchOrder);
import React from 'react';
import { shape } from 'prop-types';
import Logo from '@magalu/stereo-ui/atoms/Logo';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import { usePromoterProfile } from '@magalu/mixer-graphql';
import { routePush } from '@magalu/mixer-utils';
import PromoterHeader from '../PromoterHeader';
import { Container, GridContent, StripHeader } from './SimpleHeaderPMD.styles';
var SimpleHeaderPMD = function SimpleHeaderPMD(_ref) {
  var _structure$cookies, _data$channel, _data$channel$data, _data$channel$data$, _data$channel$data$$v, _structure$cookies2, _data$channel2, _data$channel2$data, _data$channel2$data$, _data$channel2$data$$, _promoterProfile$valu;
  var structure = _ref.structure,
    data = _ref.data;
  var mvcShowcaseName = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.mvcShowcaseName;
  var promoterProfile = usePromoterProfile({
    storeId: mvcShowcaseName
  }).promoterProfileData;
  var storeName = (data == null ? void 0 : (_data$channel = data.channel) == null ? void 0 : (_data$channel$data = _data$channel.data) == null ? void 0 : (_data$channel$data$ = _data$channel$data[1]) == null ? void 0 : (_data$channel$data$$v = _data$channel$data$.value) == null ? void 0 : _data$channel$data$$v.name) || (structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.mvcShowcaseName) || '';
  var storeAvatar = (data == null ? void 0 : (_data$channel2 = data.channel) == null ? void 0 : (_data$channel2$data = _data$channel2.data) == null ? void 0 : (_data$channel2$data$ = _data$channel2$data[2]) == null ? void 0 : (_data$channel2$data$$ = _data$channel2$data$.value) == null ? void 0 : _data$channel2$data$$.avatar) || (promoterProfile == null ? void 0 : (_promoterProfile$valu = promoterProfile.value) == null ? void 0 : _promoterProfile$valu.avatar) || '';
  return React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(Grid, {
    gridTemplateColumns: "minmax(8px, 1fr) minmax(auto, 1366px) minmax(8px, 1fr)",
    gridTemplateRows: "71px 5px",
    gridTemplateAreas: " '. content .' '. strip .'",
    "data-testid": "promoter-header-minimal"
  }, React.createElement(GridContent, {
    gridArea: "content",
    onClick: function onClick() {
      routePush({
        path: "/magazine".concat(storeName, "/"),
        spa: true
      });
    },
    "data-testid": "container-logo"
  }, React.createElement(Logo, {
    width: 138,
    height: 30
  })), React.createElement(Grid, {
    gridArea: "strip"
  }, React.createElement(StripHeader, {
    size: 5
  })))), React.createElement(PromoterHeader, {
    "static": {
      headerType: 'minimal',
      store: {
        image: storeAvatar,
        name: storeName
      }
    },
    structure: structure
  }));
};
process.env.NODE_ENV !== "production" ? SimpleHeaderPMD.propTypes = {
  data: shape({}).isRequired,
  structure: shape({}).isRequired
} : void 0;
SimpleHeaderPMD.ssr = true;
SimpleHeaderPMD.displayName = 'SimpleHeaderPMD';
export default SimpleHeaderPMD;
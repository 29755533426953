import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
var DEFAULT_CHAR_LIMIT = 300;
var DEFAULT_CHAR_OFFSET = 50;
var useReviewListing = function useReviewListing() {
  var _data$productRating, _data$productRating$u, _data$product;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$data = _ref.data,
    data = _ref$data === void 0 ? {} : _ref$data,
    _ref$structure = _ref.structure,
    structure = _ref$structure === void 0 ? {} : _ref$structure;
  var _ref2 = (structure == null ? void 0 : structure.route) || {},
    page = _ref2.page;
  var items = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$u = _data$productRating.userReviews) == null ? void 0 : _data$productRating$u.items) || [];
  var _useState = useState(items),
    _useState2 = _slicedToArray(_useState, 2),
    reviews = _useState2[0],
    setReviews = _useState2[1];

  var getTruncateSize = function getTruncateSize(str) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_CHAR_LIMIT;
    var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_CHAR_OFFSET;
    var strSize = str == null ? void 0 : str.length;
    return strSize > limit + offset ? limit : strSize;
  };

  var deepReviewCompare = function deepReviewCompare(curent, next) {
    return JSON.stringify(curent) === JSON.stringify(next);
  };

  useDidUpdateEffect(function () {
    if (!deepReviewCompare(reviews, items)) {
      setReviews([]);
    }
    setReviews(_toConsumableArray(items));
  }, [data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id]);

  useDidUpdateEffect(function () {
    setReviews(function (acc) {
      return [].concat(_toConsumableArray(acc), _toConsumableArray(items));
    });
  }, [page]);
  return {
    getTruncateSize: getTruncateSize,
    reviews: reviews
  };
};
export default useReviewListing;
import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
import styled from 'styled-components';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import HorizontalRule from '@magalu/stereo-ui/atoms/HorizontalRule';
import { Icon } from '@magalu/stereo-ui-icons';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var AgreementContent = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  line-height: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
});
export var AgreementLabel = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 13,
    lineHeight: '16px',
    paddingLeft: '16px',
    width: '144px'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var ArrowIcon = styled(Icon).attrs(function (props) {
  return _extends({
    position: 'relative'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var BenefitContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 10,
    textAlign: 'left'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var BenefitPriceText = styled(Text).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '20px',
    p: 2,
    width: '100%'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  background-color: #f1ffe7;\n  font-weight: 500;\n"])));
export var BenefitText = styled(Text).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '20px',
    pl: 2,
    pr: 2
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-size: 14px;\n"])));
export var DialogContainer = styled(Dialog)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 80vh;\n  padding: ", "px ", "px;\n  ", " {\n    width: 85%;\n    padding: ", "px;\n  }\n"])), themeGet('space.3'), themeGet('space.2'), getMediaQuery('medium'), themeGet('space.3'));
export var IconCheck = styled(Icon).attrs(function (props) {
  return _extends({
    color: '#429502',
    height: 25,
    marginRight: 9,
    minHeight: 25,
    minWidth: 25,
    width: 25
  }, props);
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral([""])));
export var IconPrize = styled(Icon).attrs(function (props) {
  return _extends({
    color: '#429502',
    height: 40,
    marginRight: 9,
    minHeight: 40,
    minWidth: 40,
    paddingLeft: 9,
    width: 40
  }, props);
})(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral([""])));
export var RuleWithMargin = styled(HorizontalRule)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin: '16px 0';\n  width: '100%';\n"])));
export var VideoContent = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  padding: 10px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-height: 260px;\n\n  &::after {\n    padding-top: 56.25%;\n    display: block;\n    content: '';\n  }\n\n  > iframe {\n    width: 100%;\n    height: 255px;\n  }\n"])));
export var VideoWrapper = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  margin: 0px 30px 8px 30px;\n  border-top: 1px solid #ccc;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n"])));
import React from 'react';
import { arrayOf, bool, shape, string, oneOf, number } from 'prop-types';

import Card from '@magalu/stereo-ui/atoms/Card';
import Text from '@magalu/stereo-ui/atoms/Text';
import Link from '@magalu/stereo-ui/atoms/Link';

import { routePush, parseDateTime } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { createCustomUrl } from '../variations.utils';

import { Container } from './List.styles';
function List(_ref) {
  var columns = _ref.columns,
    posts = _ref.posts,
    cardTheme = _ref.cardTheme,
    displaySubtitle = _ref.displaySubtitle,
    titleAs = _ref.titleAs,
    viewport = _ref.viewport,
    customBasePath = _ref.customBasePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePostClick = function handlePostClick(categoryId, title, e, slug, postId) {
    e.preventDefault();
    var recomendationLayout = 'List';
    routePush({
      path: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(postId, "/"),
      spa: true
    });
    publish('blogpost:click', {
      categoryId: categoryId,
      recomendationLayout: recomendationLayout,
      title: title
    });
  };
  var handleSubCategoryPostClick = function handleSubCategoryPostClick(category, subcategory, e) {
    e.stopPropagation();
    var subcategoryUrl = createCustomUrl(category, subcategory);
    routePush({
      path: subcategoryUrl,
      spa: true
    });
  };
  return React.createElement(Container, {
    columns: columns,
    "data-testid": "list-variation"
  }, posts == null ? void 0 : posts.map(function (_ref2) {
    var _image$urls, _parseDateTime;
    var id = _ref2.id,
      image = _ref2.image,
      title = _ref2.title,
      categoryId = _ref2.categoryId,
      categoryTitle = _ref2.categoryTitle,
      updatedAt = _ref2.updatedAt,
      subtitle = _ref2.subtitle,
      slug = _ref2.slug,
      category = _ref2.category,
      subcategory = _ref2.subcategory;
    return React.createElement(Card, {
      key: id,
      as: "a",
      href: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
      backgroundColor: cardTheme,
      enableHover: true,
      "data-testid": "list-card",
      onClick: function onClick(e) {
        return handlePostClick(categoryId, title, e, slug, id);
      },
      gridTemplateColumns: "1fr",
      gridTemplateRows: ['250px auto', '190px auto', '190px auto']
    }, React.createElement(Card.Image, {
      src: "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=fit-in/{w}x"),
      alt: image.alt,
      imageHeight: "250px",
      objectFit: "cover",
      srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
      width: viewport === 'mobile' ? 380 : 327
    }), React.createElement(Card.Body, null, React.createElement(Card.Title, {
      forwardedAs: titleAs,
      truncate: true,
      lines: 2,
      lineHeight: ['display', 'input', 'input'],
      fontSize: [3, 4, 4]
    }, title), React.createElement(Card.Subtitle, {
      fontSize: [null, 1, 1]
    }, Boolean(updatedAt) && "".concat((_parseDateTime = parseDateTime(updatedAt)) == null ? void 0 : _parseDateTime.getShortMonthYear, " | "), React.createElement(Text, {
      fontWeight: "bold",
      color: "inherit",
      display: "inline-block"
    }, categoryTitle)), displaySubtitle && React.createElement(Card.Text, {
      truncate: true,
      lines: 4,
      fontSize: [null, 3, 3]
    }, subtitle), subcategory && React.createElement(Link, {
      p: "8px 0",
      color: "inherit",
      fontWeight: "bold",
      "data-testid": "blog-subCategory-link",
      href: createCustomUrl(category, subcategory),
      onClick: function onClick(e) {
        return handleSubCategoryPostClick(category, subcategory, e);
      }
    }, subcategory.title)));
  }));
}
process.env.NODE_ENV !== "production" ? List.propTypes = {
  cardTheme: string,
  columns: number,
  customBasePath: string,
  displaySubtitle: bool,
  posts: arrayOf(shape({})).isRequired,
  titleAs: string,
  viewport: oneOf(['mobile', 'desktop'])
} : void 0;
List.defaultProps = {
  cardTheme: 'background.light',
  columns: 4,
  customBasePath: '',
  displaySubtitle: true,
  titleAs: 'h3',
  viewport: 'mobile'
};
export default List;
/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import HeaderDesktop from '@magalu/mixer-modules/build/esm/components/ecommerce/HeaderDesktop';
import PromoterHeader from '@magalu/mixer-modules/build/esm/components/ecommerce/PromoterHeader';
import Breadcrumb from '@magalu/mixer-modules/build/esm/components/ecommerce/Breadcrumb';
import Heading from '@magalu/mixer-modules/build/esm/components/ecommerce/Heading';
import SearchFilters from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchFilters';
import Row from '@magalu/mixer-modules/build/esm/components/ecommerce/Row';
import SearchHeader from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchHeader';
import SearchOrder from '@magalu/mixer-modules/build/esm/components/ecommerce/SearchOrder';
import ProductList from '@magalu/mixer-modules/build/esm/components/ecommerce/ProductList';
import Pagination from '@magalu/mixer-modules/build/esm/components/ecommerce/Pagination';
import FooterDesktopPMD from '@magalu/mixer-modules/build/esm/components/ecommerce/FooterDesktopPMD';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preconnect from '@magalu/mixer-modules/build/esm/novisual/Preconnect';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';
import Stewie from '@magalu/mixer-modules/build/esm/novisual/Stewie';
import AllIn from '@magalu/mixer-modules/build/esm/novisual/AllIn';
import Criteo from '@magalu/mixer-modules/build/esm/novisual/Criteo';
import Radware from '@magalu/mixer-modules/build/esm/novisual/Radware';

const pageModules = {
  GoogleTagManagerNoscript,
  HeaderDesktop,
  PromoterHeader,
  Breadcrumb,
  Heading,
  SearchFilters,
  Row,
  SearchHeader,
  SearchOrder,
  ProductList,
  Pagination,
  FooterDesktopPMD,
  Favicon,
  Preconnect,
  GoogleTagManagerHead,
  Stewie,
  AllIn,
  Criteo,
  Radware,
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  SelectedFilters: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/SelectedFilters'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;

import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import RawBox from '@magalu/stereo-ui/atoms/Box';
import RawButton from '@magalu/stereo-ui/atoms/Button';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var ActionButtonsWrapper = styled(RawBox)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: sticky;\n  bottom: 0px;\n  background-color: white;\n  ", " {\n    border-radius: 8px;\n  }\n"])), getMediaQuery('medium'));
export var Button = styled(RawButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-radius: 12px;\n  font-size: 16px;\n  height: 48px;\n  width: 100%;\n  margin: 0 5px;\n"])));
export var ButtonsWrapper = styled(Flex).attrs(function (props) {
  return {
    props: props
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex: 50%;\n  ", " {\n    justify-content: end;\n    flex: 3;\n    order: 3;\n  }\n"])), getMediaQuery('medium'));
export var Wrapper = styled(Flex).attrs(function (props) {
  return {
    props: props
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  line-height: 22px;\n  padding: 25px 20px;\n  width: 100%;\n  flex-wrap: wrap;\n  ", " {\n    flex-direction: row;\n    justify-content: end;\n  }\n"])), getMediaQuery('medium'));
import React from 'react';
import { bool, func, string } from 'prop-types';
import { parseVideoUrl } from '@magalu/mixer-utils';
import { AgreementContent, DialogContainer, VideoContent } from './ServicesInfo.styles';
var Dialog = function Dialog(props) {
  var agreementModalOpen = props.agreementModalOpen,
    agreementText = props.agreementText,
    handleClickEvent = props.handleClickEvent,
    open = props.open,
    videoModalOpen = props.videoModalOpen,
    videoUrl = props.videoUrl;
  return React.createElement(DialogContainer, {
    "data-testid": "dialog-container",
    onClickOutside: function onClickOutside() {
      return handleClickEvent(videoModalOpen ? 'video' : 'agreement');
    },
    bg: "background.white",
    position: "center",
    type: "dialog",
    visible: open,
    showClose: true,
    pl: 3,
    pr: 3
  }, agreementModalOpen && React.createElement(AgreementContent, {
    dangerouslySetInnerHTML: {
      __html: agreementText
    },
    "data-testid": "agreement-content"
  }), videoModalOpen && React.createElement(VideoContent, {
    pl: 3,
    pr: 3
  }, React.createElement("iframe", {
    src: parseVideoUrl(videoUrl),
    title: "YouTube video player",
    frameBorder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
    "data-testid": "video-content"
  })));
};
process.env.NODE_ENV !== "production" ? Dialog.propTypes = {
  agreementModalOpen: bool.isRequired,
  agreementText: string.isRequired,
  handleClickEvent: func.isRequired,
  open: bool.isRequired,
  videoModalOpen: bool.isRequired,
  videoUrl: string.isRequired
} : void 0;
export default Dialog;
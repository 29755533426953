import _extends from "@babel/runtime/helpers/esm/extends";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, memo, useMemo } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Collapse from '@magalu/stereo-ui/atoms/Collapse';
import Text from '@magalu/stereo-ui/atoms/Text';
import ServicesCard from '../ServicesCard';
import ServicesInfo from '../ServicesInfo';
import { ArrowIcon, ExtraInfoFlex, Wrapper } from './ServicesGroup.styles';
import { findCheckedIndex, findMaxIndexCheckedOrRecomended, optionsHideCardsCollapsed, optionsShowCardsCollapsed } from './utils';
var ServiceIds = {
  GARANTIA_ESTENDIDA: 5,
  ROUBO_E_FURTO: 3,
  TROCA_CERTA: 4
};
var ServicesGroup = function ServicesGroup(props) {
  var _visibleCards, _service$period2;
  var service = props.service;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var extraInfo = service.extraInfo;
  var onInfoClick = extraInfo.onInfoClick,
    _extraInfo$serviceGro = extraInfo.serviceGroupLayoutConfig,
    serviceGroupLayoutConfig = _extraInfo$serviceGro === void 0 ? {
      showCardsCollapsed: true,
      visibleCards: (_visibleCards = {}, _defineProperty(_visibleCards, ServiceIds.ROUBO_E_FURTO, 2), _defineProperty(_visibleCards, ServiceIds.TROCA_CERTA, 2), _defineProperty(_visibleCards, ServiceIds.GARANTIA_ESTENDIDA, 1), _visibleCards)
    } : _extraInfo$serviceGro;
  var showCardsCollapsed = serviceGroupLayoutConfig.showCardsCollapsed,
    visibleCards = serviceGroupLayoutConfig.visibleCards;
  var handleInfoClick = function handleInfoClick() {
    setIsOpen(!isOpen);
    onInfoClick(!isOpen);
  };
  var memorizedSelectedIndex = useMemo(function () {
    var _service$period;
    var options = service == null ? void 0 : (_service$period = service.period) == null ? void 0 : _service$period.options;
    return showCardsCollapsed ? findMaxIndexCheckedOrRecomended(options) : findCheckedIndex(options);
  }, [isOpen]);
  var renderShowCardsCollapsed = function renderShowCardsCollapsed(options, serviceKey) {
    var _optionsShowCardsColl;
    return (_optionsShowCardsColl = optionsShowCardsCollapsed(isOpen, memorizedSelectedIndex, options, serviceKey, visibleCards)) == null ? void 0 : _optionsShowCardsColl.map(function (option) {
      return React.createElement(ServicesCard, _extends({
        key: "period-".concat(option == null ? void 0 : option.value)
      }, option));
    });
  };
  var renderHideCardsCollapsed = function renderHideCardsCollapsed(options) {
    var _optionsHideCardsColl;
    return (_optionsHideCardsColl = optionsHideCardsCollapsed(isOpen, memorizedSelectedIndex, options)) == null ? void 0 : _optionsHideCardsColl.map(function (option) {
      return React.createElement(ServicesCard, _extends({
        key: "period-".concat(option == null ? void 0 : option.value)
      }, option));
    });
  };
  var renderGroupServicesCard = function renderGroupServicesCard(options, serviceKey) {
    if (showCardsCollapsed) {
      return renderShowCardsCollapsed(options, serviceKey);
    }
    return renderHideCardsCollapsed(options);
  };
  return React.createElement(Wrapper, {
    key: service == null ? void 0 : service.key,
    "data-testid": "service-group"
  }, renderGroupServicesCard(service == null ? void 0 : (_service$period2 = service.period) == null ? void 0 : _service$period2.options, service.key), extraInfo && Object.keys(extraInfo).length > 0 && React.createElement(Box, null, React.createElement(Collapse, {
    trigger: !isOpen
  }, React.createElement(ServicesInfo, extraInfo)), React.createElement(ExtraInfoFlex, {
    alignItems: "center",
    onClick: handleInfoClick,
    pt: 3,
    pl: 3,
    pr: 3
  }, React.createElement(Text, {
    as: "span",
    fontSize: 1,
    "data-testid": "open-service-info"
  }, "Ver mais"), React.createElement(ArrowIcon, {
    name: "Chevron".concat(isOpen ? 'Up' : 'Down')
  }))));
};
ServicesGroup.defaultProps = {
  service: {}
};
var benefitPropType = process.env.NODE_ENV !== "production" ? shape({
  description: string
}) : {};
var extraInfoPropType = process.env.NODE_ENV !== "production" ? shape({
  agreementText: string,
  benefit: arrayOf(shape({
    description: string
  })),
  label: string,
  labelBold: string,
  title: string,
  videoUrl: string
}) : {};
var serviceGroupLayoutConfigPropTypes = process.env.NODE_ENV !== "production" ? shape(_defineProperty({}, string, number)) : {};
var selectedOfferPricePropType = process.env.NODE_ENV !== "production" ? shape({
  subtitle: string,
  title: shape({
    bold: string,
    text: string
  })
}) : {};
var periodOptionPropType = process.env.NODE_ENV !== "production" ? shape({
  checked: bool,
  recommended: bool,
  selectedOfferPrice: selectedOfferPricePropType,
  title: string,
  value: number
}) : {};
var periodPropType = process.env.NODE_ENV !== "production" ? shape({
  options: arrayOf(periodOptionPropType),
  value: number
}) : {};
var servicePropType = process.env.NODE_ENV !== "production" ? shape({
  benefit: arrayOf(benefitPropType),
  extraInfo: extraInfoPropType,
  label: string,
  labelBold: string,
  period: periodPropType,
  serviceGroupLayoutConfig: serviceGroupLayoutConfigPropTypes,
  title: string,
  videoUrl: string
}) : {};
process.env.NODE_ENV !== "production" ? ServicesGroup.propTypes = {
  service: servicePropType
} : void 0;
export default memo(ServicesGroup);
export var ALLOWED_FILE_TYPES = ['image', 'video'];
export var ALLOWED_IMG_EXT = ['bmp', 'jpeg', 'jpg', 'heic', 'heif', 'png'];
export var ALLOWED_VIDEO_EXT = ['mov', 'mp4', 'quicktime'];
export var MAX_COMMENT_LENGTH = 1000;
export var MAX_IMG_HEIGHT = 1080;
export var MAX_IMG_QTD = 2;
export var MAX_IMG_SIZE = 10000000;
export var MAX_IMG_WIDTH = 1920;
export var MAX_VIDEO_QTD = 1;
export var MAX_VIDEO_SIZE = 100000000;
export var msgErrorGeneral = 'Arquivo inválido. Não há suporte para o tipo ou formato.';
export var msgErrorImgQtd = 'Só é permitido enviar no máximo 2 imagens.';
export var msgErrorImgSize = 'Não é possível enviar imagens com mais de 10MB.';
export var msgErrorVideoQtd = 'Só é permitido enviar no máximo 1 vídeo.';
export var msgErrorVideoSize = 'Não é possível enviar vídeo com mais de 100MB.';